/* eslint-disable max-len */

export default {
  REPORT__COLUMN_CS_NUMBER: 'サービス番号',
  GENERAL__DATE_FORMAT: 'YYYY-MM-DD',
  REPORT__STATUS_SUBMITTED: '提出中',
  CANT_LOGIN__ACCOUNT_RECOVERY_PROFILE_STATUS: '個人情報記入状況：',
  APPLICATION__RECORD_STATUS: '状態',
  APPLICATION__TAB_VERIFICATION: '取消認證',
  GENERAL__BUTTON_UPLOAD_ID: '上傳證件',
  PROFILE__NOTICE_1: '註：資料已進行屏蔽部分顯示，以確保安全性。',
  PROFILE__NOTICE_2: '註：會員資料填寫完成後無法自行修改，請務必填寫真實資訊。',
  ARTICLE__TICKET_CHOOSE_GAME: 'ゲームを選択してください',
  CANT_LOGIN__ITEM_RESETPWD_CONTENT:
    '只要驗證信箱或手機仍可使用，就可以透過<a href="https://account.garena.com/recovery" class="cantlogin__card-link">「忘記密碼」</a>功能自行重置密碼囉！<br />建議您先嘗試利用忘記密碼功能，並檢查是否有收到驗證信件或簡訊。<br />若您的會員資料有未詳盡填寫或填寫不實，請透過<a href="/" class="onlineservice-form__link">「線上回報單」</a>與我們諮詢。<br />※若您一直無法收到驗證碼，請點選下方按鈕申請重置密碼，讓我們協助您。',
  ARTICLE__TO_TOP: 'トップへ戻る',
  M_CANT_LOGIN__ITEM_GAMEBLOCK_CONTENT:
    '若帳號仍可使用，但遊戲遭停權無法登入，請使用被停權的帳號登入後，於客服中心首頁點選［對應遊戲］→［停權申訴］→［線上回報單］與客服中心聯繫。',
  PROFILE__PHONE_PERMANENT: '永久電話',
  VERIFICATION__ITEM_2STEPS_TITLE: '2ステップ認証',
  REPORT__FEEDBACK_PLACEHOLDER: 'ご意見を自由にご記入ください。よりよりサービスへと努めてまいります。',
  POPUP__ALERT_PROFILE_UPDATE_SUCCEED: '個人資訊更新成功。',
  M_HOME__SERVICE_RECORD_POPUP_OPTION_REPORT: 'リクエスト申請記録',
  APPLICATION__VERIFICATION_STATUS_NG_NO_COLOR_ID: '審査エラー－カラーの身分証明書を提出してください',
  GENERAL__CALENDAR_MONTH_NOV: '11月',
  HOME__USER_UNREAD: '未読',
  GENERAL__CALENDAR_DAY_WED: '水曜日',
  VERIFICATION__RESET_PROFILE_PERSONAL_DATA: '個人資訊',
  APPLICATION__VERIFICATION_STATUS_PENDING: '審査中',
  M_HOME__SERVICE_RECORD_POPUP_TITLE: '選擇申請紀錄',
  CANT_LOGIN__ACCOUNT_RECOVERY_SAME_EMAIL: '同註冊信箱',
  SEARCH__BAR_PLACEHOLDER: 'キーワードを入力して検索',
  REPORT__VIEW_UNREAD: '未読メッセージ',
  CANT_LOGIN__ACCOUNT_RECOVERY_PROFILE_WITHOUT_FULL_DATA: '記入していない又は記入エラーがあります',
  VERIFICATION__RESET_PROFILE_APPLICATION: '服務申請',
  PROFILE__EMPTY: '（尚未填寫）',
  CANT_LOGIN__ACCOUNT_RECOVERY_CONFIRM: '私はパスワード変更サービスについて理解し、メール及び電話番号の承認をキャンセルします。',
  REPORT__NO_RECORD_DESC:
    'カスタマーセンタートップページにてリクエストを申請してください<a href="/" class="reportlist-empty__link">「トップへ戻る」</a>',
  REPORT__REPLY_TITLE_USER: '詳細状況',
  APPLICATION__VERIFICATION_STATUS_OK_PROCESSING: '審査通過－進行中',
  ARTICLE__TICKET_DESC: 'リクエスト機能は24時間ご利用可能です',
  VERIFICATION__RESET_PROFILE_PERSONAL_DATA_DESC:
    ' 資料已進行屏蔽部分顯示，以確保安全性。若您仍有修改會員資料需求，<a class="profile-data__link" href="">請點擊這邊</a>來尋求協助。 ',
  ARTICLE__ID: 'FAQ番号：',
  TICKET__EMAIL_FIELD_PLACEHOLDER: '有効なメールアドレスをご記入ください',
  APPLICATION__VERIFICATION_STATUS_CANCELLED: '申請取り消し',
  REPORT__REPLY_DOWNLOAD: 'チェック',
  TICKET__NOTICE_4:
    '本システムをご利用時、攻撃的、卑猥な意味を含む内容、または弊社及び弊社社員にハラスメント、誹謗中傷、言論攻撃、担当者への暴言が見られた場合は、次の通りに対応いたします。1回目：警告、2回目：会員アカウント3日間使用停止、3回目：会員アカウント7日間使用停止、4回目：会員アカウント永久使用停止。',
  TICKET__NOTICE_3:
    'カスタマーサービスの返信後、お客様より1ヶ月以上ご返信が無い場合は、システムが自動的にリクエストをクローズとみなします。解決済及びクローズ済のリクエストには返信ができなくなりますので、再度お問い合わせいただく場合は新しくリクエストを作成してください。',
  TICKET__NOTICE_2:
    '迅速なリクエスト処理のため、重複リクエストは避けてください。また、詳細な記述にご協力をお願いいたします。内容によっては処理の時間が遅くなってしまうことがございます。ご迷惑をおかけいたしますが、ご了承くださいませ。',
  TICKET__NOTICE_1:
    'リクエストは送信時間に従って順番に処理を行います。しばらくお待ち下さい。進捗度及び返信内容を確認したい場合は、<a href="/reports" class="report-warning__link" target="_blank">「リクエスト記録」</a>よりご覧ください。',
  REPORT__DETAIL_TITLE: 'リクエスト詳細',
  CANT_LOGIN__ITEM_GAMEBLOCK_CONTENT:
    '若帳號仍可使用，但遊戲遭停權無法登入，請使用被停權的帳號登入後，於客服中心首頁點選［對應遊戲］→［停權申訴］→［線上回報單］與客服中心聯繫。',
  SEARCH__BAR_TITLE_MOBILE: 'スマートフォンゲーム',
  GENERAL__NOT_LOGGED_IN: 'ログインしていません',
  POPUP__ERROR_APPLICATION_CREATE_FAILED: '申請重置資料發生錯誤，請重新操作，或稍後再試一次。',
  POPUP__ALERT_TICKET_REPLY_RATE_SUCCEED_CONTENT: 'ご返信ありがとうございます。',
  GENERAL__CALENDAR_MONTH_FEB: '2月',
  CANT_LOGIN__ACCOUNT_RECOVERY_COUNTRY_CODE: '国コード',
  VERIFICATION__RESET_PROFILE_APPLICATION_COMPLETION_SUCCESS_TEXT:
    '申請進度請至<a href="/applications" class="profileresult-tip__link">「線上服務申請記錄」</a>查詢',
  GENERAL__CALENDAR_DAY_SUN: '日曜日',
  VERIFICATION__ITEM_ACCOUNT_LINKTEXT: '認証メールアドレス',
  TERMS__PP_LINK: 'https://contentgarena-a.akamaihd.net/legal/pp/pp_en.html',
  PROFILE__TO_ACCOUNT_CENTER: "前往<a href='https://account.garena.com' target='_blank'>帳戶中心</a>",
  PROFILE__PHONE: '聯絡電話：',
  GENERAL__BUTTON_CONFIRM: '確認',
  PROFILE__UNKNOWN: '未知',
  CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP_LAST_TOPUP_TIME: '最終チャージ日時：',
  REPORT__STATUS_STAFF_REPLIED: 'カスタマーサービスより返信済',
  REPORT__DETAIL_BASE_CATEGORY: 'カテゴリ：',
  CANT_LOGIN__ACCOUNT_RECOVERY_NOTICE: '注意事項：',
  VERIFICATION__RESET_PROFILE_APPLICATION_UPLOAD: '上傳檔案：',
  POPUP__ALERT_TICKET_REPLY_CREATE_SUCCEED_CONTENT: '返信しました。リクエスト記録にて履歴を確認してください。',
  CANT_LOGIN__ACCOUNT_RECOVERY_UPLOAD_NOTICE_1:
    '<li>本服務需提供彩色證件正本圖片。</li><li>中華民國（台澎金馬地區）之會員請提供【身分證正面】；未滿20歲之用戶，若未持有身分證，需提供健保卡及戶口名簿。</li><li>其他地區之會員請提供個人身分證件。</li>',
  CANT_LOGIN__ACCOUNT_RECOVERY_UPLOAD_NOTICE_2:
    '<li>本服務需提供彩色證件正本圖片。</li><li>中華民國（台澎金馬地區）之會員請提供【雙證件】， 年滿20歲之用戶限提供身分證正面及第二證件；未滿20歲之用戶，若未持有身分證，需提供健保卡及戶口名簿。</li><li>其他地區之會員請提供個人身分證件。</li>',
  POPUP__ERROR_404:
    'ファイルまたはリストが見つかりません。お探しの資料は既に移動された、名前が変更された、一時的に使用できないなどの可能性が考えられます。',
  POPUP__CANT_LOGIN: 'Garena 帳號無法登入嗎？',
  POPUP__ERROR_400: '操作エラー',
  M_HOME__NEWS_TITLE: '新聞與公告',
  HOME__CANT_LOGIN_DESC: '重置密碼，其他疑問',
  PROFILE__PERSONAL_INFO: '個人資訊',
  GENERAL__DATETIME_FORMAT_2: 'YYYY 年 MM 月 DD 日 HH:mm:ss',
  VERIFICATION__RESET_PROFILE_APPLICATION_RESET_PASSWORD: '重設密碼',
  REPORT__STATUS_PENDING_USER_REPLY: '返信を待っています',
  GENERAL__INPUT_ERROR_REQUIRED: '記入必須です',
  REPORT__DETAIL_CS_NUMBER: 'サービス番号：',
  HOME__FAQ: 'よくある質問',
  APPLICATION__VERIFICATION_STATUS_NG_MISMATCHED_SERVICE_REGION: '審査エラー－身分証明書のエリアが適合していません',
  GENERAL__PLACEHOLDER_DEFAULT_SELECT_GAME: 'ゲームを選択してください',
  VERIFICATION__ITEM_2STEPS_CONTENT:
    '請輸入欲認證的手機號碼，並點擊下方的傳送驗證碼，輸入手機簡訊的驗證碼送出後即完成認證。系統將預設開啟您的手機兩步驟驗證功能。建議您再度回到我的帳戶內檢查驗證狀態，並手動開啟支援遊戲兩步驟驗證的功能，以加強防護您的帳號安全。<br />※一個手機號碼僅能綁定3個Garena帳號，認證前請確認號碼是否正確。',
  POPUP__ERROR_TICKET_CREATE_FAILED: 'リクエスト作成にエラーが発生しました。しばらくしてからもう一度お試しください。',
  POPUP__ERROR_APPLICATION_APPLICATION_IN_PROGRESS: '已有申請紀錄審核中，請耐心等候處理，您可至線上服務申請紀錄查看進度。',
  TICKET__REMINDER: 'ヒント：正確にカテゴリを選択すると、専門の担当者より素早く対応を行うことができます。',
  GENERAL__CALENDAR_MONTH_MAY: '5月',
  GENERAL__TITLE: 'カスタマーサービス',
  HOME__USER_ACCOUNT_CENTER: 'アカウントセンター',
  GENERAL__CALENDAR_MONTH_MAR: '3月',
  SEARCH__BAR_ALL: '全てのサービス',
  GENERAL__CALENDAR_MONTH_DEC: '12月',
  REPORT__STATUS_FILTER_PENDING: '処理待ち',
  GENERAL__BUTTON_NOTIFICATION: '了解',
  TICKET__EMAIL: 'メールアドレス',
  APPLICATION__TAB_OTHERS: '會員服務申請',
  VERIFICATION__RESET_PROFILE_APPLICATION_NOTIFY: '請選擇新密碼接收方式：',
  PROFILE__TITLE: '我的會員資料',
  CANT_LOGIN__ITEM_ACCBLOCK_LINKTEXT: '帳號被鎖回報單',
  PROFILE__INVALID_BIRTHDATE: '（生日資訊錯誤）',
  CANT_LOGIN__ACCOUNT_RECOVERY_TITLE: '線上服務申請',
  CANT_LOGIN__ACCOUNT_RECOVERY_REMINDER:
    'ご注意：下記の欄は記入必須です。お客様の会員情報が正確に登録されていない場合は、<a href="/" class="onlineservice-form__link">オンラインリクエスト</a>よりご連絡ください。',
  SEARCH__READ_MORE: 'もっと読む',
  ARTICLE__TICKET_TITLE: 'オンラインリクエスト',
  GENERAL__BUTTON_SUBMIT_REPORT__FEEDBACK: 'フィードバック送信',
  CANT_LOGIN__ACCOUNT_RECOVERY_NOTICE_NOTICE_1:
    '身分証明書は【撮影したもの】または【スキャンしたもの】のカラーのみを受け付けています。不明瞭なもの、途切れているもの、コピーのもの、隠れている部分があるもの、および会員データと適合しないものは受け付けることができません。ご了承ください。',
  CANT_LOGIN__ACCOUNT_RECOVERY_NOTICE_NOTICE_2:
    '申請送信後、弊社にてアカウントデータとユーザーの状況に基づいて審査を行います。申し訳ございませんが、しばらくお待ちくださいませ。',
  GENERAL__LOGIN: 'ログイン',
  REPORT__LINK_TITLE: 'リクエストのアカウント連携',
  GENERAL__CALENDAR_MONTH_JAN: '1月',
  CANT_LOGIN__ACCOUNT_RECOVERY_REGISTER_EMAIL: '登録メールアドレス：',
  HOME__MOBILE_GAME_QUESTION: 'スマートフォンゲームに関する質問',
  CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_MAIN_GAME: '主に遊んでいるゲーム：',
  TICKET__NOTICE: '注意事項',
  TERMS__PP: 'プライバシーポリシー',
  REPORT__COLUMN_STATUS: '状態',
  APPLICATION__VERIFICATION_STATUS_DUPLICATED: '重複申請',
  M_HOME__CANT_LOGIN_TITLE: '帳號無法登入',
  CANT_LOGIN__ACCOUNT_RECOVERY_CHECKBOX:
    '私は <a href="https://contentgarena-a.akamaihd.net/legal/tos/tos_en.html" target="_blank" class="onlineservice-form__link">サービス規約</a> 及び報告システム注意関連事項をよく読み、全ての規定に従うことに同意します。',
  GENERAL__INPUT_ERROR_ACCOUNT_FORMAT: '帳號格式不符，請確認輸入的帳號名稱是否正確',
  M_REPORT__NO_MORE_RECORD: 'これ以上記録はありません',
  GENERAL__CALENDAR_MONTH_APR: '4月',
  REPORT__COLUMN_TOPIC_CATEGORY: '種類別',
  GENERAL__CALENDAR_DAY_MON: '月曜日',
  GENERAL__CALENDAR_MONTH_OCT: '10月',
  SEARCH__BAR_TITLE_OTHERS: 'その他',
  REPORT__FEEDBACK_GOOD: '満足',
  M_NEWS__NO_MORE_NEWS: '沒有更多新聞與公告了',
  VERIFICATION__RESET_PROFILE_APPLICATION_NOTIFY_SMS: '手機簡訊',
  ARTICLE__TICKET_SUBTITLE: '問題が解決できませんか？',
  PROFILE__GENDER_FEMALE: '女',
  POPUP__ALERT: 'ご注意',
  REPORT__STATUS_SOLVED: '解決済',
  CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP_YES: 'チャージ有り',
  VERIFICATION__RESET_PROFILE_APPLICATION_NOTIFY_EMAIL: '電子信箱',
  GENERAL__PLATFORM_FACEBOOK: 'Facebook',
  CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP_AMOUNT_PLACEHOLDER: '0',
  POPUP__ERROR_TICKET_THROTTLING:
    '申し訳ございません。現在リクエストの記入ができません。しばらくしてからもう一度お試しください。考えられる原因：<br/>1. サーバーが混み合っている<br/>2. 短時間に複数のリクエストを送信した</br>3. カスタマーセンターの回線が混み合っている</br>',
  REPORT__STATUS_REPLIED: '回答済',
  PROFILE__EMAIL_ADDRESS: '電子信箱：',
  VERIFICATION__ITEM_RESET_CONTENT:
    'お客様の認証携帯電話番号または認証メールアドレスが既にご利用いただけない場合、下記のボタンをクリックして私達にお知らせください。<br /><span class="verification__card-span1">本サービス定期要範囲：正確会員データが登録されたGarenaアカウントに限ります。</span><br />万が一、会員データに不備がある又は不正確な情報が含まれている場合、<a href="/" class="verification__card-link">「オンラインリクエスト」</a>よりご連絡ください。',
  M_VERIFICATION__RESET_PROFILE_APPLICATION_COMPLETION_BUTTON: '返回首頁',
  GENERAL__DATETIME_FORMAT: 'YYYY-MM-DD HH:mm:ss',
  APPLICATION__RECORD_ITEM: '申請項目',
  M_VERIFICATION__ITEM_RESET_CONTENT:
    '若您的認證手機或認證電子信箱已無法使用，請點擊下方按鈕，讓我們協助您。<br /><span class="verification__card-span1">本服務適用範圍：完成登錄真實會員資料的Garena帳號。</span><br />若您的會員資料有未詳盡填寫或填寫不實，請透過<a href="/m/games" class="verification__card-link">「線上回報單」</a>與我們諮詢。',
  HOME__NEWS_LEARN_MORE: '了解更多',
  HOME__USER_APPLICATION_RECORD: 'オンラインサービス申請記録',
  POPUP__ERROR_APPLICATION_FB_ACCOUNT_CANT_VERIFICATION: 'Facebook 帳號無法提供帳號安全認證服務。',
  APPLICATION__VERIFICATION_STATUS_NG_CONTACT_SUPPORT: '審査エラー－申請に失敗しました。お客様相談室に連絡してください',
  GENERAL__BUTTON_NEXT_STEP: '次へ',
  REPORT__FEEDBACK_DESC: '今回のサービスに対して、満足度を評価してください',
  POPUP__ERROR_EXCEPTION: '予期しないエラーが発生しました。しばらく経ってからもう一度お試しください。',
  ARTICLE__RELATED_ARTICLE: '関連する質問',
  APPLICATION__VERIFICATION_STATUS_COMPLETED: '申請完了',
  VERIFICATION__RESET_PROFILE_PERSONAL_DATA_NAME: '真實姓名',
  CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_LAST_LOGIN_TIME: '最終ログイン日時：',
  ARTICLE__TITLE: '{currentBaseCategoryName}よくある質問：',
  GENERAL__INPUT_ERROR_APPLICATION_PLAYER_INFO_INCOMPLETE: '遊戲資料不完整，請再次確認',
  POPUP__ERROR_PROFILE_NO_PLATFORM_USER_INFO_REQUIRED: '您的帳號無需填寫使用者資訊。',
  REPORT__CONTINUE_REPLY_TITLE: '続けてリクエストする',
  GENERAL__CALENDAR_MONTH_JUL: '7月',
  POPUP__ERROR_TICKET_LOAD_FAILED: 'リクエストの読み取りに失敗しました。しばらくしてからもう一度お試しください。',
  GENERAL__INPUT_ERROR_PHONE_FORMAT: '電話號碼格式不符，請確認輸入的電話號碼是否正確',
  CANT_LOGIN__ACCOUNT_RECOVERY_SAME_EMAIL_TIP: '登録時のメールアドレスをご記入ください',
  REPORT__STATUS_SYSTEM_CLOSED: 'システムクローズ済',
  GENERAL__SERVICE_PHONE: '客服電話：(02)7735-2000',
  POPUP__ERROR_INVALID_FILE_TYPE: 'サポートされていないファイル形式です。他のファイルを選択してください。',
  PROFILE__ID_CARD_NO: '身份證字號：',
  M_CANT_LOGIN__ITEM_ACCBLOCK_CONTENT:
    '若 Garena 帳號遭鎖定無法登入，請使用 Facebok 或是您持有的另一個 Garena 帳號登入客服中心，讓客服人員協助您。',
  VERIFICATION__RESET_PROFILE_TITLE: '申請重置資料',
  REPORT__STATUS_FILTER_PROCESSING: '処理中',
  GENERAL__INPUT_ERROR_FILE_NOT_SELECTED: 'アップロードするファイルが選択されていません',
  CANT_LOGIN__ITEM_RESETPWD_TITLE: '重置密碼',
  REPORT__LINK_CONFIRM_YES_BUTTON: 'アカウント連携確認',
  REPORT__STATUS_FILTER_ALL: '全てのリクエスト',
  APPLICATION__TAB_CANT_LOGIN: '帳號無法登入',
  LOGIN__CHOOSE_PLATFORM: 'ログイン方法を選択してください：',
  GENERAL__BUTTON_PREVIOUS_STEP: '前へ',
  POPUP__ALERT_APPLICATION_CREATE_SUCCEED_CONTENT: '已成功送出重置 Garena 帳號密碼申請，請靜待信件或簡訊通知。',
  POPUP__ALERT_TICKET_REPLY_CREATE_SUCCEED_TITLE: 'リクエスト返信成功#{csNumber}',
  REPORT__STATUS_FILTER_CLOSED: 'クローズ済',
  POPUP__ERROR_APPLICATION_LOAD_FAILED: '讀取線上服務申請紀錄發生錯誤，請稍後再試一次。',
  CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP_CHANNEL: 'チャージ方法：',
  CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_ACCOUNT_CREATED_TIME_PLACEHOLDER: 'Garenaアカウント作成日時：',
  REPORT__FEEDBACK_BAD: '不満',
  M_VERIFICATION__ITEM_2STEPS_CONTENT:
    '請輸入欲認證的手機號碼，並點擊下方的傳送驗證碼，輸入手機簡訊的驗證碼送出後即完成認證。系統將預設開啟您的手機兩步驟驗證功能。建議您再度回到我的帳戶內檢查驗證狀態，並手動開啟支援遊戲兩步驟驗證的功能，以加強防護您的帳號安全。<br />※一個手機號碼僅能綁定3個Garena帳號，認證前請確認號碼是否正確。',
  REPORT__COLUMN_GAME: 'ゲーム',
  APPLICATION__VERIFICATION_STATUS_NG_OVER_LIMIT: '審査エラー－申請回数の上限を超過しています',
  GENERAL__PLACEHOLDER_DEFAULT_SELECT_DATE: '日時を選択してください',
  GENERAL__BUTTON_SUBMIT_REPLY: '返信送信',
  HOME__VERIFICATION_DESC: '信箱認證、手機認證、取消認證',
  GENERAL__INPUT_ERROR_EMAIL_FORMAT: 'メールアドレスの形式に誤りがあります。もう一度確認してください。',
  VERIFICATION__RESET_PROFILE_APPLICATION_COMPLETION_NOTICE: '溫馨提示',
  M_CANT_LOGIN__ACCOUNT_RECOVERY_REMINDER:
    '溫馨提醒：以下資料均為必填欄位，若您的會員資料登記不正確，請透過<a href="/m/games" class="onlineservice-form__link">線上回報單</a>與我們諮詢。',
  VERIFICATION__ITEM_2STEPS_LINKTEXT: '認証携帯電話番号',
  ARTICLE__TAGS: 'FAQタグ：',
  CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_ACCOUNT_CREATED_TIME: 'Garenaアカウント作成日時：',
  PROFILE__GENDER: '性別：',
  PROFILE__GENDER_MALE: '男',
  REPORT__COLUMN_VIEW: '返信を見る',
  CANT_LOGIN__ACCOUNT_RECOVERY_CONTACT_EMAIL: '連絡メールアドレス：',
  REPORT__DETAIL_GAME: 'ゲーム：',
  GENERAL__SERVICE_TIME: '工作時間：週一至週五 9：00 ～ 13：00、14：00 ～ 18：00（例假日除外）',
  PROFILE__INFO: '帳號資料',
  POPUP__ERROR_TICKET_LINK_NOT_FOUND: 'このリンクは無効です',
  TICKET__FIELD_BASE_CATEGORY: 'カテゴリ',
  GENERAL__BUTTON_CANCEL: 'キャンセル',
  HOME__NEWS_TITLE: '最新消息',
  GENERAL__BUTTON_UPLOAD_FILE: 'ファイルアップロード',
  REPORT__FEEDBACK_RECORD_DESC: 'サービス満足度：',
  HOME__USER_TICKET_RECORD: 'マイリクエスト',
  REPORT__REPLY_ATTACHMENT_NUM: 'アップロード済ファイル数：',
  REPORT__VIEW_READ: 'チェック',
  GENERAL__INPUT_PLACEHOLDER_HALFWIDTH_INT: '半角数字でご記入ください',
  APPLICATION__RECORD_TIME: '申請時間',
  CANT_LOGIN__ITEM_ACCBLOCK_TITLE: '帳號被鎖',
  PROFILE__WARNING_1: '依會員系統服務合約，註冊帳號時，須提供完整詳實且符合真實之個人資料。',
  PROFILE__WARNING_2: '若提供之個人資料有填寫不實或原所登錄之資料已不符合真實而未更新，或有任何誤導之嫌，Garena 將有權隨時凍結此帳號。',
  PROFILE__WARNING_3: 'Garena 將不定時對會員資料進行抽查，如有符合會員系統服務合約中第五項第三點之事實，我們有權隨時終止此帳號之權利。',
  POPUP__ERROR_TICKET_REPLY_CREATE_FAILED: 'リクエストの返信にエラーが発生しました。しばらくしてからもう一度お試しください。',
  REPORT__COLUMN_CREATED_DATE: '提出日時',
  VERIFICATION__RESET_PROFILE_PERSONAL_DATA_ID: '身分證字號',
  TICKET__EMAIL_FIELD_CHECKBOX: 'カスタマーサービスより返信があった場合、メールで通知する',
  HOME__PC_GAME_QUESTION: 'オンラインゲームに関する質問',
  NEWS__TITLE: '新聞與公告',
  SEARCH__RESULT_TITLE: '現在検索しているキーワード：',
  REPORT__LINK_CONFIRM_MSG: 'お客様のリクエストとアカウントを連携しますか？',
  GENERAL__BUTTON_SUBMIT_FORM: 'フォーム送信',
  CANT_LOGIN__ITEM_GAMEBLOCK_LINKTEXT: '填寫申訴回報單',
  M_HOME__SERVICE_RECORD_POPUP_BUTTON: '戻る',
  CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP_AMOUNT: 'チャージ金額：',
  APPLICATION__TITLE: 'お問い合わせ申請履歴',
  VERIFICATION__RESET_PROFILE_APPLICATION_COMPLETION_NOTICE_2:
    '【取消認證】及【重置密碼】服務約1-3個工作天內即可完成，待處理完畢後狀態將顯示為完成申請，還請您耐心等候。',
  VERIFICATION__RESET_PROFILE_APPLICATION_COMPLETION_NOTICE_1: '狀態：審核成功 - 處理中 代表您的證件資料核對無誤並提交相關單位處理中',
  SEARCH__BREADCRUMB: '検索結果',
  POPUP__CONFIRM_LEAVE: '本当にこのページを離れますか？<br>ページを離れると、現在記入中のデータは全て失われます。',
  VERIFICATION__RESET_PROFILE_APPLICATION_SERVICE: '請勾選您欲申請的服務：',
  HOME__CANT_LOGIN_TITLE: '帳號無法登入',
  REPORT__CONTINUE_REPLY_UPLOAD_FILE: 'ファイルをアップロードする',
  GENERAL__CALENDAR_DAY_FRI: '金曜日',
  REPORT__NO_RECORD: '現在リクエスト申請記録はありません！',
  TICKET__TOS_CHECKBOX:
    '私は既に<a href="https://contentgarena-a.akamaihd.net/legal/tos/tos_en.html" target="_blank" class="report-form__link">規約&規範</a>をよく読み、オンラインリクエストシステムの注意事項を理解して遵守することに同意します。',
  VERIFICATION__RESET_PROFILE_APPLICATION_CANCEL_PHONE: '取消手機認證',
  TICKET__TOS_NOTICE: '「規約＆規範」をよく読んでからチェックしてください。',
  POPUP__ALERT_PROFILE_REDIRECT_TO_ACCOUNT_CENTER: '您的帳號平台為 Garena，會員資料可在帳戶中心進行管理。',
  TERMS__TOS_LINK: 'https://contentgarena-a.akamaihd.net/legal/tos/tos_en.html',
  VERIFICATION__ITEM_ACCOUNT_CONTENT:
    '※每組信箱僅能綁定一個 Garena 帳號，帳號如未完成電子信箱認證，將無法收取密碼重置信哦！<br />尚未收到信件嗎？ 1. 請檢查垃圾信件匣。 2. Yahoo信箱有延遲問題，可能造成您無法收到認證信件，建議改用其他電子信箱進行認證。 3. 透過<a href="/" class="verification__card-link">「線上回報單」</a>尋求幫助。',
  REPORT__REPLY_RATE_NO: 'いいえ、役に立たなかった',
  GENERAL__CALENDAR_MONTH_SEP: '9月',
  NEWS__PUBLISH_AT: '公告日期：',
  PROFILE__DESCRIPTION: '您有真實登錄資料的義務！您是 Garena 帳號的唯一合法使用者。',
  GENERAL__CALENDAR_DAY_SAT: '土曜日',
  VERIFICATION__RESET_PROFILE_APPLICATION_COMPLETION_SUCCESS_TITLE: '您已成功提交申請！',
  APPLICATION__NO_RECORD: 'お問い合わせ申請履歴がありません',
  REPORT__REPLY_ATTACHMENT_NOTICE: 'あなたのデータの個人情報を保護するため、アップロードしたファイルはカバーされました',
  REPORT__REPLY_ATTACHMENT: '添付ファイル',
  REPORT__COLUMN_BASE_CATEGORY: 'カテゴリ',
  POPUP__ALERT_TICKET_CREATE_SUCCEED_TITLE: 'リクエスト作成完了 #{csNumber}',
  TICKET__TITLE: 'リクエスト記入',
  POPUP__ERROR_APPLICATION_APPLIED_TOO_MANY_TIMES: '很抱歉，您申請的次數已超過上限，請填寫回報單與客服人員聯繫。',
  POPUP__ERROR_PROFILE_UPDATE_FAILED: '個人資訊更新失敗，請重新操作一次。',
  APPLICATION__VERIFICATION_STATUS_NG_INVALID_ID: '審査エラー－身分証明書が無効です',
  HOME__VERIFICATION_TITLE: '帳號安全認證',
  REPORT__STATUS_PENDING: '未処理',
  VERIFICATION__ITEM_ACCOUNT_TITLE: 'メールアドレス認証',
  GENERAL__CALENDAR_MONTH_JUN: '6月',
  PROFILE__PHONE_TIME: '可聯絡您的時間：',
  ARTICLE__SHOW_MORE: 'よくある質問を見る',
  M_CANT_LOGIN__ITEM_RESETPWD_CONTENT:
    '只要驗證信箱或手機仍可使用，就可以透過<a href="https://account.garena.com/recovery" class="cantlogin__card-link">「忘記密碼」</a>功能自行重置密碼囉！<br />建議您先嘗試利用忘記密碼功能，並檢查是否有收到驗證信件或簡訊。<br />若您的會員資料有未詳盡填寫或填寫不實，請透過<a href="/m/games" class="onlineservice-form__link">「線上回報單」</a>與我們諮詢。<br />※若您一直無法收到驗證碼，請點選下方按鈕申請重置密碼，讓我們協助您。',
  REPORT__FEEDBACK_TITLE: 'サービス満足度',
  CANT_LOGIN__ACCOUNT_RECOVERY_ACCOUNT: 'Garenaアカウント：',
  REPORT__REPLY_RATE_TITLE: 'この回答は役に立ちましたか？',
  PROFILE__REAL_NAME: '真實姓名：',
  CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_LAST_LOGIN_TIME_PLACEHOLDER: '例：2013年',
  GENERAL__INPUT_FILE_UPLOAD_TIP: '一度にアップロードできるファイルは4つまでです。',
  POPUP__ALERT_TICKET_CREATE_SUCCEED_CONTENT:
    'リクエストの作成が完了しました。リクエスト記録で履歴を確認してください。（サービス番号：{csNumber}）',
  M_HOME__REPORT__TITLE: 'オンラインリクエスト',
  M_HOME__SERVICE_RECORD_TITLE: '申請記録',
  REPORT__STATUS_USER_REPLIED: 'ユーザー返信済',
  PROFILE__PHONE_BACKUP: '備用電話',
  NOTIFICATION__TITLE: 'お知らせ',
  POPUP__ERROR: 'エラー',
  REPORT__DETAIL_NOT_FOUND: 'リクエストの記録が見つかりませんでした',
  REPORT__DETAIL_NOT_FOUND_DESC: '',
  PROFILE__ACCOUNT: '帳號',
  GENERAL__PLACEHOLDER_DEFAULT_SELECT: '選択してください',
  APPLICATION__VERIFICATION_STATUS_NG_BLURRED_ID: '審査エラー－身分証明書が不明瞭です',
  CANT_LOGIN__ACCOUNT_RECOVERY_REGION: 'エリアを選択してください',
  REPORT__REPLY_RATE_YES: 'はい、問題が解決しました',
  GENERAL__PLATFORM_GARENA: 'Garena',
  REPORT__FEEDBACK_RECORD_TITLE: 'サービス満足度を送信しました',
  APPLICATION__VERIFICATION_STATUS_NG_MISMATCHED_ID: '審査エラー－身分証明書資料が不適合です',
  GENERAL__INPUT_FILE_UPLOAD_INFO:
    'JPG、JPEG、PNG、LOG、AVI、WMV、MOV、MP4、RM形式のファイルがアップロード可能です。一度に4つのファイルをアップロードすることができます。',
  APPLICATION__VERIFICATION_STATUS_NG_ALREADY_VERIFIED: '審査エラー－メールアドレスまたは電話番号は既に確認済です',
  GENERAL__BUTTON_SUBMIT_TICKET: 'リクエストを送信する',
  CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP_AMOUNT_UNIT: '円',
  PROFILE__STATUS: '會員狀態',
  REPORT__TITLE: 'リクエスト記録',
  GENERAL__INPUT_PLACEHOLDER_UNDER_AGE: '未成年人消費請先經過家長同意',
  REPORT__REPLY_TITLE_STAFF: 'カスタマーセンター返信',
  SEARCH__BAR_TITLE_PC: 'オンラインゲーム',
  REPORT__LINK_CONFIRM_NO_BUTTON: 'トップへ戻る',
  REPORT__STATUS_PROCESSING: '処理中',
  CANT_LOGIN__ITEM_RESETPWD_LINKTEXT: '申請重置 Garena 帳號密碼',
  TICKET__FIELD_TICKET_TOPIC: '項目',
  GENERAL__CALENDAR_DAY_TUE: '火曜日',
  POPUP__ERROR_FILE_SIZE_EXCEEDED: 'ファイル容量が上限を超えています。他のファイルを選択してください。',
  VERIFICATION__RESET_PROFILE_APPLICATION_COMPLETION_FAILURE_TEXT: '可能因為伺服器忙碌中或客服中心回報單流量過大，請稍候再試',
  APPLICATION__VERIFICATION_STATUS_NG_INCOMPLETE_ID: '審査エラー－身分証明書資料が不足しています',
  VERIFICATION__ITEM_RESET_TITLE: '認証キャンセル',
  CANT_LOGIN__ITEM_ACCBLOCK_CONTENT:
    '若 Garena 帳號遭鎖定無法登入，請使用 Facebook 或是您持有的另一個 Garena 帳號登入客服中心，讓客服人員協助您。',
  GENERAL__CALENDAR_MONTH_AUG: '8月',
  GENERAL__INPUT_PLACEHOLDER_PHONE_TIME: '建議填寫以免打擾您休息',
  CANT_LOGIN__ACCOUNT_RECOVERY_UPLOAD: '上傳檔案：',
  REPORT__CONTINUE_REPLY_PLACEHOLDER:
    'ご報告いただいた問題は、営業時間に担当者より対応させていただきます。しばらくお待ちいただくよう、ご了承くださいませ。',
  M_HOME__SERVICE_RECORD_POPUP_OPTION_SERVICE: '線上服務申請紀錄',
  REPORT__DETAIL_TOPIC_CATEGORY: '種類別：',
  CANT_LOGIN__ACCOUNT_RECOVERY_PHONE: '電話番号：',
  PROFILE__NORMAL: '正常會員',
  CANT_LOGIN__ITEM_GAMEBLOCK_TITLE: '遊戲停權',
  M_REPORT__REPLY_VIEW_MORE: 'もっと見る',
  REPORT__DETAIL_TOPIC: '項目：',
  M_VERIFICATION__RESET_PROFILE_APPLICATION_COMPLETION_SUCCESS_TEXT:
    '申請進度請至<a href="/m/applications" class="profileresult-tip__link">「線上服務申請記錄」</a>查詢',
  PROFILE__BIRTHDATE: '出生年月日：',
  TERMS__TOS: 'サービス利用規約',
  GENERAL__CALENDAR_DAY_THU: '木曜日',
  REPORT__LINK_CONTENT: '現在ログインしているアカウント：',
  CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP: 'チャージ記録の有無：',
  GENERAL__LOGOUT: 'ログアウト',
  CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP_LAST_TOPUP_TIME_PLACEHOLDER: '例：2013年',
  TICKET__SUBMIT_NOTICE: '記入していない又は内容が不正確な項目があります',
  REPORT__STATUS_DUPLICATED: '重複リクエスト',
  REPORT__CONTINUE_REPLY_SEND_EMAIL: 'カスタマーサービスからの返信通知をオンにする',
  POPUP__ERROR_REQUIRE_LOGIN: 'ログインに失敗しました。再度お試しください。',
  M_HOME__VERIFICATION_TITLE: '帳號安全認證',
  CANT_LOGIN__ACCOUNT_RECOVERY_PROFILE_WITH_FULL_DATA: '記入は全て完了しています',
  CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP_NO: 'チャージ無し',
  REPORT__COLUMN_TOPIC: '項目',
  GENERAL__SITE_TITLE: 'Garenaカスタマーサービス',
  VERIFICATION__RESET_PROFILE_APPLICATION_COMPLETION_FAILURE_TITLE: '很抱歉！目前無法提交申請，請稍候再試。',
  GENERAL__INPUT_PLACEHOLDER_HALFWIDTH: '半角英数字でご記入ください',
  TICKET__FIELD_GAME: 'ゲーム',
  REPORT__CONTINUE_REPLY_CHANGE_EMAIL: 'メールアドレス',
  M_VERIFICATION__ITEM_ACCOUNT_CONTENT:
    '※每組信箱僅能綁定一個 Garena 帳號，帳號如未完成電子信箱認證，將無法收取密碼重置信哦！<br />尚未收到信件嗎？ 1. 請檢查垃圾信件匣。 2. Yahoo信箱有延遲問題，可能造成您無法收到認證信件，建議改用其他電子信箱進行認證。 3. 透過<a href="/m/games" class="verification__card-link">「線上回報單」</a>尋求幫助。',
  VERIFICATION__RESET_PROFILE_APPLICATION_CANCEL_EMAIL: '取消電子信箱認證',
  TICKET__QUESTION_1: 'ご質問の種類を教えてください。',
  TICKET__QUESTION_2: '具体的な質問項目を教えてください。',
  PROFILE__ADD_PERSONAL_INFO: '填寫個人資訊',
  POPUP__ERROR_APPLICATION_PROFILE_INCOMPLETE: '帳號尚未完成會員資料填寫，請前往帳戶中心進行身分證字號綁定。',
  HOME__USER_ACCOUNT_INFO: '会員データ',
  TICKET__FIELD_TICKET_TOPIC_CATEGORY: '種類別',
  SEARCH__NO_RESULT: '',
};
